export const helper = {
  copyCode(ref) {
    let testingCodeToCopy = document.getElementById(ref) || this.$refs[ref]
    console.log(testingCodeToCopy)

    testingCodeToCopy.setAttribute('type', 'text')
    testingCodeToCopy.select()

    try {
      document.execCommand('copy')
      window.getSelection()?.removeAllRanges()
      
    } catch (err) {
      console.log(err)
    }
  },
}
