import Vue from 'vue'

const alertModal = function (options, delay = 3000) {
  const AlertComponent = Vue.extend(require('@/components/Alert.vue').default)
  const currentTime = Date.now()
  if (this.lastAlertTime && currentTime - this.lastAlertTime < delay) {
    let alertElements = document.querySelectorAll('.alert')
    alertElements.forEach(function (alertElement) {
      alertElement.style.display = 'none'
    })
  }

  this.lastAlertTime = currentTime
  const instance = new AlertComponent({
    data() {
      return {
        visible: false,
        title: options.title || '',
        text: options.text || '',
        type: options.type || '',
        time: options.time || delay,
        extraClass: options.extraClass || '',
      }
    },
  })
  instance.$mount()
  document.body.appendChild(instance.$el)
}

export default alertModal
