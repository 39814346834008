const state = () => ({
  filter: {
    type: "",
    country: null,
    period: "",
    device_type: "",
    search: "",
    page: "",
    traffic_type: "",
    sort: null,
    order: "desc",
    bidding_model:"",
  },
});

const getters = {
  getFilter: (state) => {
    return state.filter;
  },
};

const mutations = {
  setFilterStore(state, payload) {
    
    if (payload) {
      state.filter[payload.field] = payload.val;
    }
  },
  clearFilterStore(state) {
    for (let key in state.filter) {
      if (key === "country") state.filter[key] = null;
      state.filter[key] = "";
    }
  },
};

const actions = {
  setFilter({commit}, payload) {
    commit("setFilterStore", payload);
  },
  clearFilter({commit}) {
    commit("clearFilterStore");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
